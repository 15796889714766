<template>
  <div>
    <SideComponente />
    <Planos/>
    <HelloWorld/>

    
  </div>
</template>

<script>
import SideComponente from '@/components/SlideComponente.vue'
import HelloWorld from '@/components/HelloWorld.vue'
import Planos from '@/components/PlanosWorld.vue'
export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    SideComponente,
    Planos
  }
  
}
</script>
