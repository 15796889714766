<template>
  <div>
<div class="topbar">
  <div class="row">
  <div class="col-xl-8 col-md-7 col-sm-7"> 
    <div class="header-top-right custom-margin-left">
      <a href="mailto:contato@bbcinternet.com.br" target="_blank">
        <ul>
          <li class="header-user-info">
            <i class="fa-solid fa-inbox"></i> contato@bbcinternet.com.br
          </li>
        </ul>
      </a>
    </div>
  </div>
  <div class="col-xl-4 col-md-5 col-sm-5">
    <div class="header-top-right">
      <a href="https://bbcinternet.sgp.net.br/accounts/central/login/?metodo=usuario_senha" target="_blank">
        <ul>
          <li class="header-user-info">
            <i class="fa-solid fa-user"></i> CENTRAL DO ASSINANTE
          </li>
        </ul>
      </a>
    </div>
  </div>
</div>
     
</div>

    <nav class="navbar navbar-expand-lg navbar-light bar">
      <div class="container">
        <div class="navbar-brand">
          <router-link to="/"><img src="@/assets/logo/bbc.png" alt="Logo" class="logo"></router-link>
        </div>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" :class="{ 'active': $route.path === '/home' }">
            <router-link to="/home" class="nav-link">Inicio</router-link>
          </li>
           <!-- Novo item no menu com submenu -->
           <li class="nav-item" @mouseover="showSubMenu" @mouseleave="hideSubMenu">
          <router-link to="/" class="nav-link">Planos Fibra</router-link>
          <!-- Submenu -->
          <ul v-show="isSubMenuVisible" class="submenu">
            <li :class="{ 'active': $route.path === '/planos' }"><router-link to="/planos">Para Casa</router-link></li>
            <li :class="{ 'active': $route.path === '/condominio' }"><router-link to="/condominio">Para Condominio</router-link></li>
          </ul>
        </li>
          <li class="nav-item" :class="{ 'active': $route.path === '/cobertura' }">
            <router-link to="/cobertura" class="nav-link">Cobertura</router-link>
          </li>
          <li class="nav-item" :class="{ 'active': $route.path === '/about' }">
            <router-link to="/about" class="nav-link">Sobre</router-link>
          </li>
          <li class="nav-item" :class="{ 'active': $route.path === '/contato' }">
            <router-link to="/contato" class="nav-link">Contato</router-link>
          </li>
          <li class="padding-button btn btn-light btn-sm">
            <a href="https://bbcinternet.sgp.net.br/accounts/central/login/?metodo=usuario_senha" target="_blank" class="nav-link"><i class="fa-solid fa-barcode"></i>   2 Via de Boleto</a>
          </li>
        </ul>
      </div>
    </nav>

    <router-view class="app"/>
    
    <div class="rodape">
      
    <div class="container">
  <div class="row row-footer col-md-12">
    <div class="col-md-4 menu-header btn-align-left">
        <img loading="lazy" src="@/assets/logo/bbc _branco.png" class="logo-footer">
        <p>A BBC Internet atende em Salvador/BA como fornecedora de internet, sempre prezando pela qualidade nos serviços e atendimento. Para mais informações sobre a empresa acesse nosso canal institucional!</p>
        <p class="p-footer">
            BBC Internet CNPJ: 37810810000113 - Todos os direitos reservados.
        </p>
    </div>
    <div class="col-md-2 ">
        <ul class="menu-footer">
            <li>INTERNET FIBRA</li>
            <li><router-link to="/planos">Planos para Casa</router-link></li>
            <li><router-link to="/condominio">Para seu Condominio</router-link></li>
        </ul>
    </div>
        <div class="col-md-3">
          <ul class="menu-footer" >
            <li>CLIENTES</li>
            <li><a href="https://bbcinternet.sgp.net.br/accounts/central/login">2ª via de boleto</a></li>
            <li><a href="https://bbcinternet.sgp.net.br/accounts/central/login" target="_blank" > Extrato de pagamento</a> </li>
              
            </ul>
        </div>
      <div class="col-md-3">
          <ul class="menu-footer" >
            <li>ATENDIMENTO</li>
            <li><a href="https://bbcinternet.sgp.net.br/accounts/central/login" target="_blank">Central de Atendimento</a></li>
            <li><a href="/" data-toggle="modal" data-target="#AgendarTel">Agendar Telefonema</a></li>

            <li>Entre em contato
            <br>
            <span> <a href="tel:71992574841">(71) 992574841</a></span>
            </li>
          </ul>
      </div>
</div></div>
</div>
<div class="whatsapp-fixo">
<a href="https://api.whatsapp.com/send?phone=5571992574841&amp;text=Ol%C3%A1!%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20os%20planos%20da%20BBC Internet." target="_blank">
<img src="@/assets/home-content/whatsapp.png" width="200px" height="80px" alt="Fale Conosco pelo WhatsApp">
</a>
</div>
</div>
</template>
<script>
export default {

  data() {
    return {
      isSubMenuVisible: false,
    };
  },
  methods: {
    showSubMenu() {
      this.isSubMenuVisible = true;
    },
    hideSubMenu() {
      this.isSubMenuVisible = false;
    },
  },
};
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@import '~@fortawesome/fontawesome-free/css/all.css';

/* Estilize os ícones Flaticon */
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; /* Ou o peso da fonte necessário */
  margin-right: 5px; /* Ajuste o espaçamento conforme necessário */
}
.custom-margin-left {
  margin-left: 31%; /* Ajuste o valor conforme necessário */
}

.topbar {
    border-block-end: 1px solid #e9e7e7;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    height:2%;
}
.bar{
  border-block-end: 1px solid #f1f1f1;
}
/* Estilos para o submenu */
.submenu {
  list-style: none;
  padding: 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 4;
  width: 200px; /* Largura desejada */
}

.submenu li {
  padding: 10px;
  text-align: left;
}

.submenu li:hover {
  background-color: #f5f5f5;
}

.whatsapp-fixo {
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 999;
}
.whatsapp-fixo a {
    display: inline-block;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
.p-footer {
    padding-top: 2rem;
}
.rodape {
    background-color: #001a8d !important;
    border: none !important;
}
.row-footer {
    width: 100%;
    align-items: center;
    background-color: #001a8d;
    color: white;
    margin: auto;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    padding: 2rem;
}
ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}
.container {
  
    max-width: 1150px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.btn-align-left {
    text-align: left;
    padding-left: 0;
}
.menu-footer {
    text-align: left;
}
.menu-footer>:first-child {
    padding-bottom: 2.5rem;
}
.menu-footer>:first-child {
    font-size: 16px;
    font-weight: bold;
}
ul.menu-footer>li {
    display: block;
    font-size: 15px;
    font-weight: 300;
}
ul.menu-footer>li a {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
}
a {
    text-decoration: none !important;
    color: #666;
}
img {
    vertical-align: middle;
    border-style: none;
}
p {
    font-weight: 300;
}
body, blockquote, dl, dd, h1, h2, h3, h4, h5, h6, figure, p, pre {
    margin: 0;
}
footer p {
    color: #fff;
    font-size: 0.7em;
}
.row-footer>:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
}
.row-footer>:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
}
.row-footer>:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.float-left {
    float: left!important;
}
.float-right {
    float: right!important;
}
.d-lg-block {
    display: block!important;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.padding-button{
  padding-inline: 20px;
}
.navbar-brand {
  display: flex;
  align-items: center;
}
.nav-item {
  align-content:baseline;
  margin-right: 5px;
  border-radius: 5px; /* Arredonda as bordas */
  padding-left: 10px;
  
}

.nav-item:hover {
  background-color: #e2e2e2; /* Cor desejada ao passar o cursor */
  color: #000000; /* Cor do texto ao passar o cursor */
}
.logo {
  max-height: 50px; /* Ajuste a altura conforme necessário */
  margin-right: 10px; /* Espaçamento à direita do logo */
}

.navbar-nav {
  margin-top: 35px;
}

.active a.router-link-exact-active {
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
}

.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  padding-bottom: 20px;
  
}
.linkhover{
  color: #cd1515;
}

</style>