import { createRouter, createWebHistory  } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundView from '../views/NotFoundView.vue'


const routes = [
  {
    path: '/home',
    alias: ['/', '/main', '/welcome'],
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  },
  {
    path: '/planos',
    name: 'planos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/PlanosView.vue')
  },
  {
    path: '/condominio',
    name: 'condominio',
    component: () => import(/* webpackChunkName: "about" */ '@/views/CondominioView.vue')
  },
  {
    path: '/cobertura',
    name: 'cobertura',
    component: () => import(/* webpackChunkName: "about" */ '@/views/CoberturaView.vue')
  },
  {
    path: '/contato',
    name: 'contato',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ContatoView.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
